var RendiliCore = {
	Json: {
		Get: function(parameters) {
			if (typeof parameters == 'string') {
				parameters = { url: parameters };
			}
			var promise = {
				onSuccess: null,
				onError: null,
				then: function(success, error) {
					this.onSuccess = success;
					this.onError = error;
				}
			};
			$.ajax({
				type: 'GET',
				url: parameters.url,
				data: parameters.data,
				success: function(data) { promise.onSuccess(data); },
				error: function(xhr, status) { promise.onError(status); },
				dataType: 'json'
			});
			return promise;
		},
		Post: function(parameters) {
			if (typeof parameters == 'string') {
				parameters = { url: parameters };
			}
			var promise = {
				onSuccess: null,
				onError: null,
				then: function(success, error) {
					this.onSuccess = success;
					this.onError = error;
				}
			};
			$.ajax({
				type: 'POST',
				url: parameters.url,
				data: parameters.data,
				success: function(data) { promise.onSuccess(data); },
				error: function(xhr, status) { promise.onError(status); },
				dataType: 'json'
			});
			return promise;
		},
		PostJsonData: function(parameters) {
			if (typeof parameters == 'string') {
				parameters = { url: parameters };
			}
			var promise = {
				onSuccess: null,
				onError: null,
				then: function(success, error) {
					this.onSuccess = success;
					this.onError = error;
				}
			};
			$.ajax({
				type: 'POST',
				url: parameters.url,
				data: JSON.stringify(parameters.data),
				success: function(data) { promise.onSuccess(data); },
				error: function(xhr, status) { promise.onError(status); },
				dataType: 'json'
			});
			return promise;
		}
	}
}